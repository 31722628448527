import Button from "components/common/Button";
import withLogin from "components/Login/withLogin";
import { WhyUsSectionData } from "components/ReviewsPage/constants";
import { pageRoutes } from "constant/routes";
import { useFetchUser } from "lib/user";
import Image from "next/image";
import { useRouter } from "next/router";

const WhyUsSection = ({ initLogin }) => {
  const { user } = useFetchUser();
  const router = useRouter();

  return (
    <div className="flex max-w-page-width mx-auto flex-col items-center justify-center py-10 px-4 md:py-20 md:px-6">
      <h2 className="text-[28px] md:text-[44px] md:leading-[52px] md:w-[80%] text-blue-950 font-bold pb-4 text-center">
        Why Students <span className="text-primary">Love Us</span>
      </h2>
      <div className="flex flex-col md:flex-row gap-4">
        {WhyUsSectionData.map((el) => (
          <div
            key={el?.title}
            className="flex flex-col items-center text-center py-6 mb-6"
          >
            <Image src={el.imgSrc} alt={el.title} width={94} height={94} />
            <h3 className="text-[#1b1d6a] font-bold mt-5 mb-2.5 text-base md:text-xl">
              {el.title}
            </h3>
            <p className="text-sm md:text-base">
              <span className="text-primary font-bold">
                {el.description.split(" ")[0]}
              </span>
              {` ${el.description
                .split(" ")
                .slice(1)
                .join(" ")}`}
            </p>
          </div>
        ))}
      </div>
      <Button
        onClick={() => {
          user ? router.push(pageRoutes.INSCHOOL_DASHBOARD) : initLogin();
        }}
        customCss="rounded-xl py-4 px-6"
      >
        Apply For Education Loan
      </Button>
    </div>
  );
};

export default withLogin(WhyUsSection);
