import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";
import { useState } from "react";

const ReviewSearchInput = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="relative w-full mb-4">
      <div className="absolute h-12">
        <div className="flex items-center h-full pl-3">
          <Image
            src={`${imageBasePath}/assets/images/testimonials/search-icon.svg`}
            alt="search icon"
            width={20}
            height={20}
            className="opacity-40"
          />
        </div>
      </div>
      <input
        id={`field-${name}`}
        className={`h-12 px-4 pl-11 border-[2px] rounded-lg w-full text-sm leading-tight border-[#b3b0fc]`}
        placeholder="Search by university..."
        onChange={(e) => {
          const inputValue = e?.target?.value;
          setSearchValue(inputValue);
          onChange(inputValue);
        }}
        value={searchValue}
      />
      <style jsx>{`
        input {
          color: #2e2c57;
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #443eff;
        }
        input::placeholder {
          color: #8e8e8e;
          opacity: 1;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default ReviewSearchInput;
