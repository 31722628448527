import { logEvent } from "components/Analytics/google-analytics";
import withLogin from "components/Login/withLogin";
import { pageRoutes } from "constant/routes";
import { useFetchUser } from "lib/user";
import { pageName } from "lib/utils/events_utils";
import { useRouter } from "next/router";
import { trackClick } from "scripts/segment";

const BottomCta = ({ initLogin }) => {
  const router = useRouter();
  const { user } = useFetchUser();

  return (
    <div className="bg-blue-600 px-4 py-4 flex justify-center">
      <a
        onClick={() => {
          if (user) {
            logEvent("LF-Reviews", "Resume Application", "");
            router.push(pageRoutes.INSCHOOL_DASHBOARD);
          } else {
            logEvent("LF-Reviews", "Get Loan Offer", "");
            trackClick(pageName.REVIEWS, {
              widgetName: "Footer",
              widgetFormat: "Banner",
              contentName: "Get Loan Offer",
              contentFormat: "Button",
            });
            initLogin();
          }
        }}
        className="cursor-pointer text-white font-bold text-center inline-block bg-primary w-2/3 py-2 rounded md:w-1/3 md:py-3 lg:w-1/5"
      >
        {user ? "Resume Application" : "Get Loan Offer"}
      </a>
    </div>
  );
};

export default withLogin(BottomCta);
