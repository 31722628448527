import RatingsSection from "components/ReviewsPage/RatingsSection";
import withWindowDimensions from "components/shared/withWindowDimensions";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";

const HeroSection = ({ isMobileSized }) => {
  return (
    <div>
      <div className="relative content-section py-10 px-4 md:py-20 md:px-6 bg-[#ebe9ff] first-letter:">
        <div className="max-w-page-width mx-auto">
          <div className="flex flex-col gap-8 md:flex-row w-full text-center md:text-left items-center">
            <div className="md:flex md:w-1/2 md:gap-6 md:flex-col md:justify-center">
              <h1 className="text-[28px] md:text-[44px] md:leading-[52px] md:w-[80%] text-blue-950 font-bold">
                What our students have to say about us
              </h1>
              {!isMobileSized && <RatingsSection />}
            </div>
            <Image
              src={`${imageBasePath}/assets/images/testimonials/hero-image-web.png`}
              alt="Reviews Illustration"
              width={552}
              height={572}
              className={isMobileSized ? "mr-3 -ml-3 mb-6" : ""}
              priority
            />
            {isMobileSized && <RatingsSection />}
          </div>
        </div>
      </div>
      <style jsx>{`
        .content-section {
          background-color: #ebe9ff;
          background-image: url("/assets/images/testimonials/dot-grid.svg"),
            url("/assets/images/testimonials/ellipse-20.svg"),
            url("/assets/images/testimonials/ellipse-20mm.svg"),
            url("/assets/images/testimonials/ellipse-bl.svg");
          background-position: 40% 100%, 40% 0, 50%, 0 100%;
          background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
          background-size: auto, auto, auto, auto;
          padding-top: 80px;
          padding-bottom: 80px;
        }
        @media (max-width: 767px) {
          .content-section {
            background-image: url("/assets/images/testimonials/dot-grid.svg"),
              url("/assets/images/testimonials/ellipse-20.svg"),
              url("/assets/images/testimonials/ellipse-20mm.svg"),
              url("/assets/images/testimonials/ellipse-bl.svg");
            background-position: 40% 100%, 40% 0, 50%, 0 100%;
            background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
            background-size: auto, auto, auto, auto;
          }
        }
      `}</style>
    </div>
  );
};

export default withWindowDimensions(HeroSection);
