import { useEffect, Fragment } from "react";
import PressResourcesCarousel from "components/shared/PressResourcesCarousel";
import HeroSection from "components/ReviewsPage/HeroSection";
import WhyUsSection from "components/ReviewsPage/WhyUsSection";
import FeaturedTestimonials from "components/ReviewsPage/FeaturedTestimonials";
import CTABanner from "components/ReviewsPage/CTABanner";
import AllTestimonials from "components/ReviewsPage/AllTestimonials";
import HowToSection from "components/ReviewsPage/HowToSection";
import Investors from "components/ReviewsPage/Investors";
import Layout from "components/Layout";
import MainFooter from "components/MainFooter";
import { logPageView } from "components/Analytics/google-analytics";
import { SECONDS_IN_12_HOURS } from "lib/utils/constants";
import BottomCta from "components/ReviewsPage/BottomCta";

const Reviews = ({ testimonials }) => {
  const featuredTestimonials = testimonials?.filter(
    (testimonial) => testimonial?.is_featured
  );

  useEffect(() => {
    logPageView();
  }, []);

  return (
    <Layout
      title="Reviews"
      showNavLinks
      invertLogo={false}
      showBackground
      description="Read student reviews of Leap Finance's Post Graduate Student Loans. Discover how affordable, flexible loans are helping students achieve their study abroad goals. Learn from real experiences how Leap Finance supports students with transparent loan options for higher education."
    >
      {() => {
        return (
          <Fragment>
            <div className="mt-[70px]">
              <HeroSection />
              <WhyUsSection />
              <FeaturedTestimonials testimonials={featuredTestimonials} />
              <CTABanner />
              <AllTestimonials testimonials={testimonials} />
              <HowToSection />
              <Investors />
              <PressResourcesCarousel
                showSubTitle={false}
                shouldCenterTitle
                titleColor="text-[#1b1d6a]"
              />
              <BottomCta />
              <MainFooter />
            </div>
          </Fragment>
        );
      }}
    </Layout>
  );
};

export default Reviews;

export const getStaticProps = async () => {
  const props = {};
  try {
    const axios = (await import(`axios`)).default;
    const res = await axios.get(
      `${process.env.KnowledgeBaseURL}/reviews?platform=FINANCE&_limit=-1`
    );
    props.testimonials = res?.data?.sort(
      (a, b) => b.review?.length - a.review?.length
    );
  } catch (error) {
    console.error(error);
  } finally {
    return { props, revalidate: SECONDS_IN_12_HOURS };
  }
};
