import { imageBasePath } from "lib/utils/imageUtils";

export const experienceRatingsData = [
  {
    label: "Seamlessness (4.4/5)",
    altText: "4.4/5 rating",
  },
  {
    label: "Responsiveness (4.3/5)",
    altText: "4.3/5 rating",
  },
  {
    label: "Overall Experience (4.3/5)",
    altText: "4.3/5 rating",
  },
];

export const WhyUsSectionData = [
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/collateral-free-loan.svg`,
    title: "Collateral free loan",
    description:
      "75% of our students mentioned that they loved that we offered collateral free loan",
  },
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/hassle-free-process.svg`,
    title: "Hassle free process",
    description:
      "67% of our students found it easy and hassle free to follow our online loan application process",
  },
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/quick-sanction.svg`,
    title: "Quick Sanction",
    description:
      "73% of our students chose us for the Fastest Sanction Letter among all loan providers.",
  },
];

export const HowToSectionData = [
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/collateral-free-loan.svg`,
    title: "Calculate EMI",
    linkUrl: "/calculator",
    linkText: "View calculator",
  },
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/hassle-free-process.svg`,
    title: "Check Eligibility",
    linkUrl: "/courses",
    linkText: "Check now",
  },
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/quick-sanction.svg`,
    title: "Start Application",
    linkUrl: "/login",
    linkText: "Apply now",
    showLoginPopUp: true,
  },
  {
    imgSrc: `${imageBasePath}/assets/images/testimonials/get-offer.svg`,
    title: "Get Offer",
    linkUrl: "/login",
    linkText: "Apply now",
    showLoginPopUp: true,
  },
];
