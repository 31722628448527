import withLogin from "components/Login/withLogin";
import { HowToSectionData } from "components/ReviewsPage/constants";
import { pageRoutes } from "constant/routes";
import { useFetchUser } from "lib/user";
import Image from "next/image";
import { useRouter } from "next/router";

const HowToSection = ({ initLogin }) => {
  const router = useRouter();
  const { user } = useFetchUser();

  return (
    <div className="bg-[#1b1d6a]">
      <div className="flex flex-col max-w-page-width mx-auto py-16 px-6 md:py-20 xl:px-0">
        <h2 className="w-full text-[28px] md:text-[44px] text-white md:leading-[52px] md:w-[80%] font-bold pb-10 text-center self-center">
          Get Your Loan Offer In 4 Easy Steps
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {HowToSectionData.map((data) => (
            <div
              key={data.title}
              className="p-6 bg-white rounded flex flex-col items-center"
            >
              <Image
                src={data.imgSrc}
                alt={data.title}
                width={94}
                height={94}
              />
              <h3 className="text-[#1b1d6a] font-bold mt-5 mb-2.5 text-base md:text-xl">
                {data.title}
              </h3>
              <a
                href={data.linkUrl}
                onClick={(e) => {
                  e.preventDefault();
                  if (data.showLoginPopUp) {
                    user
                      ? router.push(pageRoutes.INSCHOOL_DASHBOARD)
                      : initLogin();
                  } else {
                    window.open(data.linkUrl);
                  }
                }}
                className="text-primary underline text-sm md:text-base"
              >
                {data.linkText}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withLogin(HowToSection);
