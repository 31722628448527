import TestimonialCard from "components/ReviewsPage/TestimonialCard";

const FeaturedTestimonials = ({ testimonials }) => {
  return (
    <div className="flex max-w-page-width mx-auto flex-col items-center justify-center py-10 px-4 md:py-20 xl:px-0">
      <h2 className="text-[28px] md:text-[44px] md:leading-[52px] md:w-[80%] text-blue-950 font-bold pb-10 text-center">
        Featured Testimonials
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {testimonials?.map((testimonial) => (
          <TestimonialCard
            key={testimonial?.id}
            studentName={testimonial?.["Name"]}
            collegeName={testimonial?.["college_name"]}
            rating={testimonial?.["rating"]}
            reviewText={testimonial?.["review"]}
            reviewLink={testimonial?.["review_link"]}
            reviewSource={testimonial?.["review_source"]}
            linkedInUrl={testimonial?.["social_platform"]?.[0]?.link}
            profileImageUrl={testimonial?.["user_image"]?.url}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturedTestimonials;
