import { experienceRatingsData } from "components/ReviewsPage/constants";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";

const RatingsSection = () => (
  <div className="flex flex-col items-center md:items-start gap-6 mb-6 md:mb-0 z-30">
    {experienceRatingsData?.map((data) => (
      <div key={data.label} className="flex flex-col items-center md:items-start">
        <Image
          src={`${imageBasePath}/assets/images/testimonials/ratings.png`}
          width={152}
          height={24}
          alt={data?.altText}
          className="mb-2"
        />
        <div className="text-primary text-base md:text-xl">{data.label}</div>
      </div>
    ))}
  </div>
);

export default RatingsSection;
