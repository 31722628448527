import Button, { buttonTypes } from "components/common/Button";
import ReviewSearchInput from "components/ReviewsPage/ReviewSearchInput";
import TestimonialCard from "components/ReviewsPage/TestimonialCard";
import { useState } from "react";

const AllTestimonials = ({ testimonials }) => {
  const [visibleTestimonialsCount, setVisibleTestimonialsCount] = useState(9);
  const [filteredTestimonials, setFilteredTestimonials] = useState(
    testimonials
  );

  const loadMoreTestimonials = () => {
    setVisibleTestimonialsCount((prevCount) => prevCount + 9);
  };

  const onSearchChange = (searchInput) => {
    const resetCount = 9;
    const searchQuery = searchInput?.toLowerCase();

    if (searchQuery) {
      const filteredTestimonials = testimonials?.filter((testimonial) =>
        testimonial?.["college_name"]?.toLowerCase()?.includes(searchQuery)
      );

      const sortedTestimonials = filteredTestimonials?.sort(
        (a, b) => b.review?.length - a.review?.length
      );
      setFilteredTestimonials(sortedTestimonials);
    } else {
      setFilteredTestimonials(testimonials);
    }
    setVisibleTestimonialsCount(resetCount);
  };

  return (
    <div className="flex max-w-page-width mx-auto flex-col items-center justify-center py-10 px-4 md:py-20 xl:px-0">
      <h2 className="text-[28px] md:text-[44px] md:leading-[52px] md:w-[80%] text-blue-950 font-bold pb-10 text-center">
        Find what students from the university you are applying to have to say
      </h2>
      <div className={`relative w-full mb-4`}>
        <ReviewSearchInput onChange={onSearchChange} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {filteredTestimonials
          ?.slice(0, visibleTestimonialsCount)
          ?.map((testimonial) => (
            <TestimonialCard
              key={testimonial?.id}
              studentName={testimonial?.["Name"]}
              collegeName={testimonial?.["college_name"]}
              rating={testimonial?.["rating"]}
              reviewText={testimonial?.["review"]}
              reviewLink={testimonial?.["review_link"]}
              reviewSource={testimonial?.["review_source"]}
              linkedInUrl={testimonial?.["social_platform"]?.[0]?.link}
              profileImageUrl={testimonial?.["user_image"]?.url}
            />
          ))}
      </div>
      {visibleTestimonialsCount < filteredTestimonials?.length && (
        <Button
          variant={buttonTypes.SECONDARY}
          onClick={loadMoreTestimonials}
          customCss="mt-8 px-8"
        >
          Load more
        </Button>
      )}
    </div>
  );
};

export default AllTestimonials;
