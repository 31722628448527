import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";

const TestimonialCard = ({
  studentName,
  collegeName,
  rating,
  reviewText,
  reviewLink,
  reviewSource,
  linkedInUrl,
  profileImageUrl,
}) => (
  <div className="shadow-[3px_3px_50px_0px_rgba(90,96,168,0.122)] p-4 md:p-6 rounded flex flex-col justify-between">
    <div className="mb-8">
      <p className="text-[#1b1d6a] text-justify">{reviewText}</p>
      {reviewLink && (
        <p className="text-sm text-[#979899]">
          <a className="underline hover:text-[#000]" href={reviewLink}>
            View original
          </a>
          <span> from </span>
          <span className="font-bold">{reviewSource}</span>
        </p>
      )}
    </div>
    <div className="flex w-full gap-2 items-center">
      {profileImageUrl && (
        <Image
          src={profileImageUrl}
          alt="Profile photo"
          width={48}
          height={48}
        />
      )}
      <div className="w-full">
        <div className="flex justify-between w-full">
          <div className="flex">
            <p className="text-sm font-bold md:text-base text-[#1b1d6a]">
              {studentName}
            </p>
            {linkedInUrl && (
              <a href={linkedInUrl} target="_blank">
                <Image
                  src={`${imageBasePath}/assets/images/testimonials/linkedin-icon.svg`}
                  alt="linkedin icon"
                  width={24}
                  height={24}
                />
              </a>
            )}
          </div>
          <div className="flex items-center gap-1">
            <span>{rating}</span>
            <Image
              src={`${imageBasePath}/assets/images/testimonials/star-icon.svg`}
              alt="Rating icon"
              width={24}
              height={24}
            />
          </div>
        </div>
        <p className="text-sm uppercase text-[#6964ff]">{collegeName}</p>
      </div>
    </div>
  </div>
);

export default TestimonialCard;
