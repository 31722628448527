import Button from "components/common/Button";
import withLogin from "components/Login/withLogin";
import { useFetchUser } from "lib/user";
import { useRouter } from "next/router";

const CTABanner = ({ initLogin }) => {
  const { user } = useFetchUser();
  const router = useRouter();

  return (
    <div className="max-w-page-width mx-auto px-4 xl:px-0">
      <div className="cta-div items-center">
        <h2 className="leading-none font-bold text-[24px] md:text-[38px] text-[#1b1d6a]">
          Ready to start your
        </h2>
        <h2 className="leading-2 font-bold text-[24px] md:text-[38px] text-primary mb-6">
          Study Abroad Journey?
        </h2>
        <Button
          onClick={() => {
            user ? router.push("/applications") : initLogin();
          }}
          customCss="rounded-lg py-3 px-6 lg:px-16"
        >
          Apply Now
        </Button>
      </div>
      <style jsx>{`
        .cta-div {
          background-image: url("https://d3qj1pefcqovqy.cloudfront.net/61a669408e2c4d16ae6ee42a_Smiling_Emoji_Illustration_ffca257317.png"),
            url("https://d3qj1pefcqovqy.cloudfront.net/61a66940e85fc85469e41df4_Speaker_Illustration_9b58c0824d.png"),
            url("https://d3qj1pefcqovqy.cloudfront.net/61adc25c2e6017221787721b_61a669a04a4771582349a8be_Gradient_Group_min_0f5324bcb7.png"),
            linear-gradient(#fff, #fff);
          background-position: 10% 80%, 95% 100%, 50%, 0 0;
          background-repeat: no-repeat, no-repeat, no-repeat, repeat;
          background-size: 147px, 160px, cover, auto;
          border-radius: 16px;
          flex-direction: column;
          padding-top: 80px;
          padding-bottom: 80px;
          display: flex;
        }
        @media (max-width: 767px) {
          .cta-div {
            background-image: url("https://d3qj1pefcqovqy.cloudfront.net/61a669408e2c4d16ae6ee42a_Smiling_Emoji_Illustration_ffca257317.png"),
              url("https://d3qj1pefcqovqy.cloudfront.net/61a66940e85fc85469e41df4_Speaker_Illustration_9b58c0824d.png"),
              url("https://d3qj1pefcqovqy.cloudfront.net/61adc25c2e6017221787721b_61a669a04a4771582349a8be_Gradient_Group_min_0f5324bcb7.png");
            background-position: 5% 80%, 95% 100%, 50%;
            background-repeat: no-repeat, no-repeat, no-repeat;
            background-size: 64px, 80px, cover;
          }
        }
      `}</style>
    </div>
  );
};

export default withLogin(CTABanner);
