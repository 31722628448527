import * as React from "react";
import debounce from "lodash/debounce";

export const buttonTypes = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const buttonSizes = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const Button = ({
  variant = buttonTypes.PRIMARY,
  size = buttonSizes.MEDIUM,
  children,
  onClick,
  isDisable,
  isFullWidth,
  customCss,
  delay = 30,
  ...props
}) => {
  const buttonTypeCss = (buttonType) => {
    if (isDisable) {
      return "text-gray-1180 bg-lightgray-100 border-gray-1180";
    }
    switch (buttonType) {
      case "primary":
        return "text-white bg-primary";
      case "secondary":
        return "text-primary bg-white border-primary";
      default:
        return "";
    }
  };

  const buttonSizeCss = (buttonSize) => {
    switch (buttonSize) {
      case "small":
        return "text-sm px-2 py-1";
      case "medium":
        return "text-base px-3 py-2";
      case "large":
        return "text-xl px-6 py-2";
      default:
        return "";
    }
  };

  // debouncing to avoid button thrashing
  const onClickHandler = debounce(() => {
    if (!isDisable && onClick) {
      onClick();
    }
  }, delay);

  return (
    <button
      type="button"
      className={[
        "font-bold rounded-sm border",
        buttonTypeCss(variant),
        buttonSizeCss(size),
        isDisable ? "cursor-not-allowed" : "cursor-pointer",
        isFullWidth && "w-full",
        customCss ? customCss : "",
      ].join(" ")}
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
