import InvestorsSection from "components/shared/InvestorsSection";

const Investors = () => {
  return (
    <div className="flex flex-col max-w-page-width mx-auto py-12 px-4 md:py-16 xl:px-0">
      <h2 className="w-full text-[28px] md:text-[44px] text-[#1b1d6a] md:leading-[52px] md:w-[80%] font-bold pb-10 text-center self-center ">
        We are backed by
      </h2>
      <InvestorsSection />
    </div>
  );
};

export default Investors;
